import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/trade`;
}

export const createTrade = async (req) => {
  const res = await axiosService.post(`${url}/transfer`, req);
  return handleResponse(res);
};

export const tradeExport = async (req) => {
  const res = await axiosService.get(`${url}/surveyor-export`, req);
  return handleResponse(res);
};

export const transactionStats = async (req) => {
  const res = await axiosService.get(`${url}/transaction-stats`, req);
  return handleResponse(res);
};

export const maxLongShort = async (req) => {
  const res = await axiosService.get(`${url}/max-long-short`, req);
  return handleResponse(res);
};
