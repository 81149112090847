import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/role`;
}

export const roleSelect = async () => {
  const res = await axiosService.get(`${url}/list-base-role`);
  return handleResponse(res);
};
