import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/trade`;
}

export const transactionSurveyorIndex = async (query) => {
  const res = await axiosService.get(`${url}/surveyor-transactions`, query);
  return handleResponse(res);
};

export const transactionSurveyorExport = async (query) => {
  const res = await axiosService.get(`${url}/surveyor-export`, query);
  return handleResponse(res);
};

export const transactionTellerIndex = async (query) => {
  const res = await axiosService.get(`${url}/teller-transactions`, query);
  return handleResponse(res);
};

export const transactionTellerExport = async (query) => {
  const res = await axiosService.get(`${url}/teller-export`, query);
  return handleResponse(res);
};

export const countStautus = async (query) => {
  const res = await axiosService.get(`${url}/status-stats`, query);
  return handleResponse(res);
};

export const transactionReject = async (body) => {
  const res = await axiosService.post(`${url}/reject`, body);
  return handleResponse(res);
};

export const transactionApprove = async (body) => {
  const res = await axiosService.post(`${url}/approve`, body);
  return handleResponse(res);
};

export const transactionConfirm = async (body) => {
  const res = await axiosService.post(`${url}/confirm`, body);
  return handleResponse(res);
};

export const createBuyTransaction = async (body) => {
  const res = await axiosService.post(`${url}/buy`, body);
  return handleResponse(res);
};

export const createSellTransaction = async (body) => {
  const res = await axiosService.post(`${url}/sell`, body);
  return handleResponse(res);
};

export const createSellTransactionNew = async (body) => {
  const res = await axiosService.post(`${url}/sell-by-stock`, body);
  return handleResponse(res);
};

export const canSell = async (query) => {
  const res = await axiosService.get(`${url}/can-sell`, query);
  return handleResponse(res);
};

export const canSellNew = async (query) => {
  const res = await axiosService.get(`${url}/customer-assets`, query);
  return handleResponse(res);
};

export const transactionChangeInProcess = async (body) => {
  const res = await axiosService.post(`${url}/in-process`, body);
  return handleResponse(res);
};

export const cancelRequestTrade = async (body) => {
  const res = await axiosService.post(`${url}/cancel-request`, body);
  return handleResponse(res);
};

export const transactionDetail = async (query) => {
  const res = await axiosService.get(`${url}/detail`, query);
  return handleResponse(res);
};
