import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/access-role`;
}

export const list = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const employeeExport = async (query) => {
  const res = await axiosService.get(`${url}/export`, query);
  return handleResponse(res);
};

export const removeRole = async (id) => {
  const res = await axiosService.post(`${url}/delete-role`, { id });
  return handleResponse(res);
};

export const listFunction = async () => {
  const res = await axiosService.get(`${url}/access-action-group-for-assign`);
  return handleResponse(res);
};

export const createRole = async (dataReq) => {
  const res = await axiosService.post(`${url}/save`, dataReq);
  return handleResponse(res);
};

export const currentRole = async (dataReq) => {
  const res = await axiosService.get(`${url}/current-assign-user`, dataReq);
  return handleResponse(res);
};

export const currentRoleGroup = async (dataReq) => {
  const res = await axiosService.get(`${url}/current-assign-role`, dataReq);
  return handleResponse(res);
};

export const saveRoleAssign = async (dataReq) => {
  const res = await axiosService.post(`${url}/save-role-assign`, dataReq);
  return handleResponse(res);
};

export const saveUserAssign = async (dataReq) => {
  const res = await axiosService.post(`${url}/save-user-assign`, dataReq);
  return handleResponse(res);
};
