import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'admin') {
  host = `${process.env.API_ADMIN_URL}/member-package`;
}

export const memberPackages = async (params) => {
  const res = await axiosService.get(`${host}/list`, params);
  return handleResponse(res);
};

export const memberPackageSave = async (req) => {
  const res = await axiosService.post(`${host}/save`, req);
  return handleResponse(res);
};

export const getMemberPackageConfigs = async (req) => {
  const res = await axiosService.get(`${host}/configs`, req);
  return handleResponse(res);
};

export const getMemberPackageConfigKeys = async (req) => {
  const res = await axiosService.get(`${host}/config-keys`, req);
  return handleResponse(res);
};

export const memberPackageSaveConfigs= async (req) => {
  const res = await axiosService.post(`${host}/save-configs`, req);
  return handleResponse(res);
};
