import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/stock`;
}

export const stocks = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const stockDerivative = async (data) => {
  const res = await axiosService.post(`${url}/save-derivative`, data);
  return handleResponse(res);
};

export const stockConfig = async (data) => {
  const res = await axiosService.post(`${url}/save-configs`, data);
  return handleResponse(res);
};

export const stockConfigGet = async (query) => {
  const res = await axiosService.get(`${url}/config-list`, query);
  return handleResponse(res);
};

export const exportStock = async (query) => {
  const res = await axiosService.get(`${url}/export`, query);
  return handleResponse(res);
};

export const changeStatus = async (query) => {
  const res = await axiosService.post(`${url}/status`, query);
  return handleResponse(res);
};

export const addList = async (query) => {
  const res = await axiosService.post(`${url}/save`, query);
  return handleResponse(res);
};

export const holdByCustomer = async (query) => {
  const res = await axiosService.get(`${url}/customer-stock-list`, query);
  return handleResponse(res);
};

export const overview = async () => {
  const res = await axiosService.get(`${url}/total-overview`);
  return handleResponse(res);
};
